import { cn } from "../utils";

interface FieldProps {
  error?: string;
  children?: React.ReactNode;
}

export const Field = ({ error, children }: FieldProps) => {
  return (
    <div className="grid w-full gap-2">
      {children}
      {error && <p className={cn("text-warning flex items-center text-xs font-medium")}>{error}</p>}
    </div>
  );
};
